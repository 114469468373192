<template>
  <v-container>
    <personnel-list-table></personnel-list-table>
  </v-container>
</template>

<script>
import PersonnelListTable from "@/components/personnel_components/PersonnelListTable";

export default {
  name: "PersonnelPage",
  components: {PersonnelListTable},
  mounted() {
    document.title = 'Agrani Bank Legal Suit System | Personnel List'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>