<template>
  <v-dialog
      max-width="600px"
      v-model="dialog"
  >
    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">Edit</span>
      </v-tooltip>
    </template>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="personnel_no" name="Personnel No"
                                    rules="required|max_value:99999999"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Personnel No*"
                      hint="Personnel no"
                      required
                      autocomplete="nope"
                      v-model="personnel.personnel_no"
                      maxlength="8"
                      counter
                      type="number"
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="name" name="Name" rules="required" v-slot="{ errors }">
                  <v-text-field
                      label="Name*"
                      hint="Personnel Name"
                      required
                      autocomplete="nope"
                      v-model="personnel.name"
                      maxlength="256"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="father_name" name="Father's Name" rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Father's Name*"
                      hint="Father's Name"
                      required
                      autocomplete="nope"
                      v-model="personnel.father_name"
                      maxlength="256"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="mobile_no" name="Mobile No" rules="required|min:11|max:15"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Mobile No*"
                      hint="Mobile no"
                      required
                      autocomplete="nope"
                      v-model="personnel.mobile_no"
                      maxlength="15"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="email" name="Email" rules="required|email" v-slot="{ errors }">
                  <v-text-field
                      label="Email*"
                      hint="Personnel Email"
                      required
                      autocomplete="nope"
                      v-model="personnel.email"
                      maxlength="256"
                      counter
                      type="email"
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"

export default {
  name: "add_edit_form",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    submit_loading: false,
    dialog: false,
    personnel: {
      id: null,
      personnel_no: '',
      name: '',
      father_name: '',
      email: '',
      mobile_no: '',
    },
  }),
  methods: {
    async add_new() {
      this.axios_conf
      let data = {...this.personnel}
      // console.log(this.zone.circle)
      await axios.post('personnel/personnelviews/', data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.$refs.vform.reset()
            this.$refs.vform.resetValidation()
            this.dialog = false
            this.$emit('form_status', true)
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                personnel_no: error.response.data.errors.personnel_no,
                name: error.response.data.errors.name,
                father_name: error.response.data.errors.father_name,
                email: error.response.data.errors.email,
                mobile_no: error.response.data.errors.mobile_no,
              });
            }
          })
      this.submit_loading = false
    },
    async edit() {
      this.axios_conf
      // console.log(this.zone)
      let data = {...this.personnel}
      // if (this.branch.zone.id) {
      //   data.zone = this.branch.zone.id
      // }
      await axios.patch(`personnel/personnelviews/${this.personnel_no}/update`, data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$emit('form_status', true)
          })
          .catch((error) => {
            // console.log(error.response.data)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              // console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                personnel_no: error.response.data.errors.personnel_no,
                name: error.response.data.errors.name,
                father_name: error.response.data.errors.father_name,
                email: error.response.data.errors.email,
                mobile_no: error.response.data.errors.mobile_no,
              });
            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new()
      } else if (this.action === 'edit') {
        this.edit()
      }
    },
    // async get_zones() {
    //   this.axios_conf
    //   this.zone_loading = true
    //   await axios.get("user/zonal_office")
    //       .then((resp) => {
    //         this.zones = resp.data.zones
    //         this.$store.commit('setJWT', resp.data.key)
    //       })
    //   this.zone_loading = false
    // },
  },
  watch: {
    passed_item(val) {
      // console.log(val)
      this.personnel = JSON.parse(JSON.stringify(val))
      // console.log(this.zone)
    },
    // zone(val) {
    //   console.log(val)
    // }
  },
  mounted() {
    if (this.passed_item) {
      this.personnel = JSON.parse(JSON.stringify(this.passed_item))
    }
  }
}
</script>

<style scoped>
</style>